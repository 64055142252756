// extracted by mini-css-extract-plugin
export var backgroundImage = "home-products-module--background-image--uS51K";
export var button = "home-products-module--button--aPdwr";
export var buttonNew = "home-products-module--button-new--qRT+V";
export var changeCountProducts = "home-products-module--change-count-products--iTJH3";
export var contentFirst = "home-products-module--content-first--WaSJR";
export var contentFirstMobile = "home-products-module--content-first-mobile--ZNz2m";
export var contentLeft = "home-products-module--content-left--e0W7P";
export var contentWrapper = "home-products-module--content-wrapper--UGNKJ";
export var hidden = "home-products-module--hidden--SFK5I";
export var main = "home-products-module--main--I2KQx";
export var productWrapper = "home-products-module--product-wrapper--E6Eek";
export var productWrapperAnimate = "home-products-module--product-wrapper-animate--dEUju";
export var quoteFirst = "home-products-module--quoteFirst--jsKo+";
export var quoteTwo = "home-products-module--quoteTwo--hTS77";
export var rotateButton = "home-products-module--rotate-button--zSrMr";
export var rotateCounterAnimation2 = "home-products-module--rotate-counter-animation2--K0ref";
export var rotateCounterAnimation3 = "home-products-module--rotate-counter-animation3--asoTP";
export var rotateCounterAnimation4 = "home-products-module--rotate-counter-animation4--jhbeR";
export var rotateCounterClockwiseAnim2 = "home-products-module--rotateCounterClockwiseAnim2--I5pFZ";
export var rotateCounterClockwiseAnim3 = "home-products-module--rotateCounterClockwiseAnim3--QGfyz";
export var rotateCounterClockwiseAnim4 = "home-products-module--rotateCounterClockwiseAnim4--bcdW4";
export var secondWrapper = "home-products-module--second-wrapper--44dzG";
export var subtitle = "home-products-module--subtitle--hlYbQ";
export var titleContainer = "home-products-module--title-container--ZvpZY";
export var topPadding = "home-products-module--top-padding--G9T+D";
export var visible = "home-products-module--visible--QWQpm";